import React from 'react'
import { CSVLink } from "react-csv";

function ExportButton(props) {
    const { exportData } = props

    return (
        <CSVLink
            data={ exportData }
            filename={ `Reports_${(new Date()).toISOString()}.csv` }
            className="text-white px-4 py-2 rounded border-light bg-primary mx-3 my-2"
            id="exportButton"
            target="_blank"
        >
                Export
        </CSVLink>
    )
}

export default ExportButton
