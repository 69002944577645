import React from 'react';
import Timeline from './Timeline';
import Table from './Table'
import { tableHeads } from '../constants/tableHeads'


function ProductDemo(props) {
    const { results } = props

    return (
        <Timeline>
            <div className="pb-4">
                <div className="d-flex justify-content-between mb-3">
                    <span className="timeline-component-title">MAIN CONTENT WITH HTML TAGS</span>
                </div>
                <div className="widgets displayBox col-6">
                    {
                        results[0]
                    }
                </div>
            </div>
            <div className="pb-4">
                <div className="d-flex justify-content-between mb-3">
                    <span className="timeline-component-title">PLAIN TEXT</span>
                </div>
                <div className="widgets displayBox col-6">
                    {
                        results[1]
                    }
                </div>
            </div>
            <div className="pb-4">
                <div className="d-flex justify-content-between mb-3 col-7 pl-0">
                    <span className="timeline-component-title">KEYWORDS</span>
                    <span className="table-limit">*Limited to 15 items</span>
                </div>
                <div className="widgets displayBox col-7">
                    <Table tableData={ {tableHead: tableHeads['keywordsExtractor'], tableBody: results[2]} } className="table table-borderless table-striped"/>
                </div>
            </div>
            <div className="pb-4">
                <div className="d-flex justify-content-between mb-3 col-7 pl-0 align-items-end">
                    <span className="timeline-component-title">LONG KEYWORDS</span>
                    <span className="table-limit">*Limited to 15 items</span>
                </div>
                <div className="widgets displayBox col-7">
                    <Table tableData={ {tableHead: tableHeads['longKeywordsCreatorTextInput'], tableBody: results[3]} } className="table table-borderless table-striped"/>
                </div>
            </div>
            <div className="pb-4">
                <div className="d-flex justify-content-between mb-3 col-7 pl-0 align-items-end">
                    <span className="timeline-component-title">PREDICTED CATEGORY</span>
                    <span className="table-limit">*Limited to 2 categories</span>
                </div>
                <div className="widgets displayBox col-7">
                    <Table tableData={ {tableHead: tableHeads['taxonomyMapper'], tableBody: results[4]} } className="table table-borderless table-striped"/>
                </div>
            </div>
            <div>
                <div className="d-flex justify-content-between mb-3 col-7 pl-0 align-items-end">
                    <span className="timeline-component-title">SUGGESTED URLS</span>
                    <span className="table-limit">*Limited to 25 URLs</span>
                </div>
                <div className="widgets displayBox col-7">
                    <Table tableData={ {tableHead: tableHeads['recommendationEngine'], tableBody: results[5]} } className="table table-borderless table-striped"/>
                </div>
            </div>
        </Timeline>
    )
}

export default ProductDemo
