import sha256 from 'crypto-js/sha256';

/* 
    Function: checkPassword
    Input   : pwd (password)  <type: String>
    Output  : Boolean value on password validation  <type: Boolean>
*/
const encrypt = password => sha256(`thirdpresence${password}`).toString()

export const checkPassword = password => {
    // variable declaration
    const appPassword = process.env.REACT_APP_PASSWORD

    // returns boolean response
    return encrypt(password) === encrypt(appPassword)
}