import React, { useEffect, useState } from 'react'
import Sort from './assets/Sort.svg'
import SortAsc from './assets/SortAsc.svg'
import SortDesc from './assets/SortDesc.svg'

function TableHead(props) {
    const { tableHead, setSortProps } = props
    const [ clickedOn, setClickedOn ] = useState(null)
    const [ ascending, setAscending ] = useState(false)
    const [ sortIcon, setSortIcon ] = useState(Sort)

    const handleClick = key => {
        setAscending(key === clickedOn ? !ascending : true)
        setClickedOn(key)
    }

    useEffect(() => {
        setSortProps && setSortProps({key: clickedOn, order: ascending ? "asc" : "desc"})
        setSortIcon(ascending ? SortAsc : SortDesc)
        // eslint-disable-next-line
    }, [ clickedOn, ascending ])

    return (
        <>
            {
                tableHead.map(tHead => (
                    tHead.sortable ? (
                        <th key={ tHead.key } className="clickable" onClick={() => handleClick(tHead.key) }>
                            { tHead.title }
                            <img src={ clickedOn === tHead.key ? sortIcon : Sort } alt="sort-icons" className="sort-icon" />
                        </th>
                    ) : (
                        <th key={ tHead.key }>{ tHead.title }</th>
                    )
                ))
            }
        </>
    )
}

export default TableHead
