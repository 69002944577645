import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Padlock } from '../assets/misc/padlock.svg';
import { checkPassword } from '../services/Auth';
import './components.css';

/* 
    Function: Login
    Input   : n/a
    Output  : react-component (render) + token in localStorage
*/
function Login() {
    // variable declarations
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);

    // handler function - called when form has changed
    const handleFormChange = event => {
      setIsInvalid(false);
      setPassword(event.target.value);
    };

    // handler function - called when form is submitted
    const login = event => {
      event.preventDefault();
      if(checkPassword(password)) {
        localStorage.setItem('ninaToken', "CT")
        history.push("/")  
      }
      setIsInvalid(true)
    };

    // response/render > start
    return (
        <div className="login d-flex flex-column align-items-center bg-light">
          <Padlock height="60" width="45"/>
          <span className="password-protected">Password protected</span>
          <form onSubmit={ login }>
                {
                    isInvalid  && <span className="password-error">Incorrect password. Try again!</span>
                }
                <input className="password-input form-control form-control-sm mb-2" type="password" name="password" placeholder="Enter password" onChange={ handleFormChange }/>
                <button className="btn btn-block btn-primary login-button" type="submit"> 
                    Login
                </button>
          </form>
        </div>
    )
    // response/render < end
}
  
export default Login;