const pageConstants = {
    keywordsExtractor: {
        title: "Keyword extractor",
        inputBox: {
            label: "ENTER TEXT",
            isTextArea: true,
            buttonText: "Extract keywords",
            placeholder: "Paste/Type your text here."
        },
        resultsDisplay: "table",
        payloadKey: "text"
    },
    longKeywordsCreatorTextInput: {
        title: "Long keyword creator",
        inputBox: {
            label: "ENTER TEXT",
            isTextArea: true,
            buttonText: "Create long keywords",
            placeholder: "Paste/Type your text here."
        },
        resultsDisplay: "table",
        payloadKey: "text"
    },
    longKeywordsCreatorKeywordsInput: {
        title: "Long keyword creator",
        inputBox: {
            label: "KEYWORDS (COMMA SEPARATED)",
            isTextArea: true,
            buttonText: "Create long keywords",
            placeholder: "Paste/Type your keywords here."
        },
        resultsDisplay: "table",
        payloadKey: "keywords"
    },
    mainContentExtractor: {
        title: "Main content extractor",
        inputBox: {
            label: "ENTER URL",
            isTextArea: false,
            buttonText: "Extract text",
            placeholder: "https://www.example.com/"
        },
        resultsDisplay: "displayBox",
        payloadKey: "url"
    },
    taxonomyMapper: {
        title: "Taxonomy mapper",
        inputBox: {
            label: "KEYWORDS (COMMA SEPARATED)",
            isTextArea: true,
            buttonText: "Predict category",
            placeholder: "Paste/Type your keywords here."
        },
        resultsDisplay: "table",
        payloadKey: "keywords"
    },
    recommendationEngine: {
        title: "Recommendation engine",
        inputBox: {
            label: "KEYWORDS/LONG-KEYWORDS (COMMA SEPARATED)",
            isTextArea: true,
            buttonText: "Suggest URLs",
            placeholder: "Paste/Type your keywords here."
        },
        resultsDisplay: "table",
        payloadKey: "keywords"
    },
    productDemo: {
        title: "Product demo",
        inputBox: {
            label: "ENTER URL",
            isTextArea: false,
            buttonText: "Submit",
            placeholder: "https://www.example.com/"
        },
        payloadKey: "url"
    }
}

export default pageConstants