import React from 'react'
import { Route, Redirect } from "react-router-dom"
import pageConstants from '../constants/pageConstants'


function PrivateRoute ({ component: Component, componentKey, ...rest }) {

    return (
        <Route {...rest} render={() => (
            localStorage.getItem("ninaToken") === "CT"
                ? <Component { ...pageConstants[componentKey] } componentKey={ componentKey } />
                : <Redirect to='/login' />
        )} />
    )
}

export default PrivateRoute