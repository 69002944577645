const constraints = {
    keywords: { min: 8, max: 100 },
    text: { min: 500, max: 2000 },
}


const errorMessages = cues => {
    const { min, max, invalid, unit } = cues

    const messages = {
        min: `Minimum ${min} ${unit || "characters"} required`,
        max: `Maximum ${max} ${unit || "characters"} allowed`,
        special: "Special characters not allowed",
        invalid: `Invalid ${invalid}`
    }

    return Object.entries(messages).filter(([k, v]) => k in cues)[0][1]
}

const helperTexts = Object.fromEntries(Object.entries(constraints).map(([k, v]) => [
    k, 
    `Minimum ${v.min} and maximum ${v.max} ${k === "keywords" ? "keywords" : "characters"} allowed.`
]))

export { errorMessages, constraints, helperTexts }