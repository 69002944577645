import React from 'react'

function InfoDisplay(props) {
    const { start, end, total } = props.info

    return (
        <span className="mx-3">Showing { !!total && `${start + 1} to ${ end } of` } { total } items</span>
    )
}

export default InfoDisplay
