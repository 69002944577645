import React from 'react'

function PageLength(props) {
    const { pageLength, setPageLength } = props
    const pageLengthOptions = [10, 25, 50, 100, 500]

    return (
        <select className="page-length" onChange={e => setPageLength(e.target.value) }>
            {
                pageLengthOptions.map(opt => (
                    <option value={ opt } selected={ pageLength === opt }>{ opt }</option>
                ))
            }
        </select>
    )
}

export default PageLength
