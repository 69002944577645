import './App.css';
import './components/components.css';
import Routes from './Routes';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';

function App() {
	return (
		<BrowserRouter>
	    	<Route exact path="/login" component={ Login } />
			<div className="App d-flex">
				<Sidebar />
				<div className="flex-grow-1 container-fluid">
					<Routes />
				</div>
			</div>
		<Redirect to="/" />
		</BrowserRouter>
	);
}

export default App;
