
const calculateBytes = (value) => {

    if(isNaN(value) || ["", null, undefined].includes(value) || typeof value === "boolean" || value instanceof Array) throw new Error("Value is non-numeric.")
    const bytes = parseFloat(value)
    if(bytes < 0) throw new Error("Value is negative.")

    const denominations = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return bytes === 0 ? "0 B" : `${parseFloat((bytes / (1024 ** i)).toFixed(2))} ${denominations[i]}`
}

export { calculateBytes }