import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import sidebarMenu from './constants/sidebarMenu';
import { BasicLayout, PrivateRoute } from './components'

function Routes() {
    const routes = [];
    sidebarMenu.forEach(item => {
        if (item.subMenu) {
            item.subMenu.forEach(sub => routes.push(
                <PrivateRoute exact path={ item.path + sub.path } component={ BasicLayout } componentKey={ item.key + sub.key } />
            ))
            routes.push(
                <Redirect from={ item.path } to={ item.path + item.subMenu[0].path } />
            )
        } else {
            routes.push(
                <PrivateRoute exact path={ item.path } component={ BasicLayout } componentKey={ item.key } />         
            )
        }
    })

    return (
        <div>
            <Switch>
                { routes }
                <Redirect exact from="/" to={ sidebarMenu[0].path } />
            </Switch>
        </div>
    )
}

export default Routes
