import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import sidebarMenu from '../constants/sidebarMenu';
import useWindowDimensions from '../utils/WindowDimensions';
import Logo from '../assets/logo/LogoIcon.svg';
import TextLogo from '../assets/logo/TextLogo.svg';
import LogoutIcon from '../assets/sidebar/LogoutIcon.svg';
import { ReactComponent as CollapseIcon } from '../assets/sidebar/CollapseIcon.svg';


function Sidebar() {
    const [collapseButtonVisibility, setCollapseButtonVisibility] = useState(false);
    const [collapse, setCollapse] = useState(!!localStorage.getItem("collapsed") || false);
    const [clickedItem, setClickedItem] = useState(null)
    const { width } = useWindowDimensions();
    collapse ? localStorage.setItem("collapsed", "collapsed") : localStorage.removeItem("collapsed") 

    useEffect(() => setCollapse(width < 1450), [ width ])


    return (
        <div className={ `bg-white sidebar d-flex flex-column justify-content-between ${ collapse && 'collapsed'}` }
            onMouseEnter={() => setCollapseButtonVisibility(true)} 
            onMouseLeave={() => setCollapseButtonVisibility(false)}
        >
            <div>
                <div className="logo-zone bg-light d-flex align-items-center">
                    <img src={ Logo } alt="logo" className={ `ml-${ collapse ? '3' : '4' }` } height="32" width="32"/>
                    <img src={ TextLogo } alt="logo" className={ `ml-2 mt-1 ${ collapse && "d-none" }` }/>
                </div>
                
                <div className={ `${ collapse || "menu" } my-4` } >
                    {
                        sidebarMenu.map((item, idx) => (
                                <div key={ idx }>
                                    <NavLink 
                                        to={ item.path } 
                                        key={ item.key } 
                                        className="sidebar-menu-item" 
                                        activeClassName={ `text-${ (!!item.subMenu && !collapse) || "selected bg-light" }` }
                                        onClick={() => setClickedItem(item.key)}    
                                    >
                                        <img src={ clickedItem === item.key ? item.selected : item.icon } alt="menu icon" className="shadow-sm rounded-circle" />
                                        <span className={ `ml-2 ${ collapse && "d-none" }` }>{ item.text }</span>
                                    </NavLink>
                                    <div className={ `${ (clickedItem === item.key && !collapse) || "d-none" }` }>
                                        {
                                            item.subMenu && item.subMenu.map(sub => (
                                                <NavLink 
                                                    to={ item.path + sub.path } 
                                                    key={ sub.key } 
                                                    className="sidebar-menu-item ml-5" 
                                                    activeClassName="text-selected bg-light"
                                                >
                                                    <span className={ `ml-2 ${ collapse && "d-none" }` }>{ sub.text }</span>
                                                </NavLink>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
            </div>

            <div className={ `${ collapse || "logout-area" } py-3` }>
                <Link to="/login" onClick={() => localStorage.removeItem("ninaToken")}>
                    <div className={`logout bg-light py-2 pl-${ collapse ? '3' : '4' } mt-3`}>
                        <img src={ LogoutIcon } alt="logout" />
                        <span className={ `ml-2 ${collapse && "d-none" }` }>Logout</span>
                    </div>
                </Link>
            </div>
            {
                collapseButtonVisibility && (
                    <CollapseIcon 
                        className={ `collapse-button ${ collapse && "rotate-button" }`} 
                        onClick={() => collapse ? setCollapse(false) : setCollapse(true) }  
                    />
                )
            }
        </div>
    )
}

export default Sidebar
