import React from 'react'

function DisplayBox(props) {
    const { text, html } = props

    return (
        <div className="row">
            <div className="col-4">
                <p className="widget-title"><b>PLAIN TEXT</b></p>
                <div className="widgets non-editable">
                    { text }
                </div>
            </div>
            <div className="col-5 px-1">
                <p className="widget-title"><b>TEXT RETAINING HTML TAGS</b></p>
                <div className="widgets non-editable">
                    { html }
                </div>
            </div>
        </div>
    )
}

export default DisplayBox
