import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Results from './Results';
import Loading from './Loading';
import Request from '../services/Request'
import MessageQueue from './MessageQueue'
import { pathFor } from '../constants/apiPaths'
import { tableHeads } from '../constants/tableHeads'
import { validateInput } from '../utils/validations';
import { errorMessages } from '../constants/messages'


function BasicLayout(props) {
    const { title, inputBox, resultsDisplay, payloadKey, componentKey } = props;
    const { label, isTextArea, buttonText, placeholder } = inputBox;
    const [ inputValue, setInputValue ] = useState("")
    const [ isClicked, setIsClicked ] = useState(false)
    const [ results, setResults ] = useState(null)
    const [ messageQueue, setMessageQueue ] = useState([])
    const [ errorMessage, setErrorMessage ] = useState("")
    const location = useLocation()

    const handleChange = event => {
        setErrorMessage("")
        setMessageQueue([])
        setIsClicked(false)
        setInputValue(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setResults(null)

        const error = validateInput(inputValue, payloadKey)

        if (!error) {    
            try{
                setIsClicked(true)
                
                const payload = {}
                payload[payloadKey] = inputValue
                if (payloadKey === "keywords") {
                    payload[payloadKey] = inputValue.trim().split(',').map(val => val.trim())
                }
                
                const { statusCode, jsonBody } = await Request(pathFor[componentKey], payload)
                if (statusCode === 200) {
                    ["mainContentExtractor", "productDemo"].includes(componentKey) ? setResults(jsonBody) : setResults({tableHead: tableHeads[componentKey], tableBody: jsonBody})
                } else {
                    setMessageQueue([jsonBody])
                }
                
            } catch (error) {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        } else {
            setErrorMessage(error)
        }
    }

    useEffect(() => {
        setInputValue("")
        setIsClicked(false)
        setResults(null)
        setMessageQueue([])
        setErrorMessage("")
    }, [location])

    return (
        <div>
            <h1>{ title }</h1>
            <form className="bg-light px-3 pt-4 pb-3 row">
                <div className="form-group col-5">
                    <label for="currentInputBox" className="d-flex justify-content-between">
                        <p className="my-0">{ label }</p>
                        <span className="text-danger">
                            <i>{ errorMessage }</i>
                        </span>
                    </label>
                    {
                        isTextArea ? (
                            <textarea className="form-control" id="currentInputBox" rows="5" placeholder={ placeholder } onChange={ handleChange} value={ inputValue }/>
                        ) : (
                            <input type="text" className="form-control" id="currentInputBox" placeholder={ placeholder } onChange={ handleChange} value={ inputValue }/>
                        )
                    }
                </div>
                <button type="submit" className={ `btn btn-${inputValue.length ? "primary" : "disabled"} align-self-end mb-3` } onClick={ handleSubmit } disabled={ !inputValue }>{ buttonText }</button>
            </form>     
            <div className="p-3">
                {
                    !isClicked ? (
                        <></>
                    ) : results ? (
                        <Results display={ resultsDisplay } results={ results } />
                    ) : (
                        <Loading />
                    )
                }
            </div>
            <MessageQueue messageQueue={ messageQueue } />
        </div>
    )
}

export default BasicLayout
