import React from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'

function Table(props) {
    const { tableData, className, setSortProps, setMessageQueue } = props
    let { tableHead, tableBody } = tableData


    return (
        <table className={ className }>
            <thead>
                <tr className="text-muted">
                    <TableHead tableHead={ tableHead } setSortProps={ setSortProps }/>
                </tr>
            </thead>
            <tbody>
                <TableBody tableBody={ tableBody } tableHead={ tableHead } setMessageQueue={ setMessageQueue } /> 
            </tbody>
        </table>
    )
}
                   
export default Table
