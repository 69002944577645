
const tableHeads = {
    keywordsExtractor: [
        {
            key: "value",
            title: "Value"
        },
        {
            key: "score",
            title: "Score"
        }
    ],
    longKeywordsCreatorTextInput:  [
        {
            key: "value",
            title: "Value"
        },
        {
            key: "score",
            title: "Score"
        }
    ],
    longKeywordsCreatorKeywordsInput: [
        {
            key: "value",
            title: "Value"
        },
        {
            key: "score",
            title: "Score"
        }
    ],
    taxonomyMapper: [
        {
            key: "category",
            title: "Category"
        },
        {
            key: "rank",
            title: "Rank"
        }
    ],
    recommendationEngine: [
        {
            key: "url",
            title: "URL"
        },
        {
            key: "rank",
            title: "Rank"
        }
    ]
}

export { tableHeads }