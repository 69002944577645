import React from 'react'

function Timeline(props) {
    return (
        <div>
            {
                props.children.map((child, idx) => (
                    <div key={ idx }>
                        <div className="milestone"></div>
                        <div className={ `timeline-component ${idx === props.children.length - 1 && "border-left-0"}` }>
                            { child }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Timeline
