
const pathFor = {
    keywordsExtractor: "/keywords-extractor",
    longKeywordsCreatorTextInput: "/long-keywords-extractor/text",
    longKeywordsCreatorKeywordsInput: "/long-keywords-extractor/keywords",
    mainContentExtractor: "/content-extractor",
    taxonomyMapper: "/taxonomy-mapper",
    recommendationEngine: "/recommendation-engine",
    productDemo: "/product-demo"
}

export { pathFor }
