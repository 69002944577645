import React from 'react';
import DisplayBox from './DisplayBox'
import ProductDemo from './ProductDemo';
import { DataTable } from './Table'

function Results(props) {
    const { display, results } = props

    return (
        <div>
            <p className="result-text">RESULTS</p>
            {
                !display ? (
                    <ProductDemo results={ results }/>
                ) : display === "table" ? (
                    <DataTable tableData={ results }/>
                ) : (
                    <DisplayBox { ...results }/>
                )
            }
        </div>
    )
}

export default Results
