
const urlOf = path => process.env.REACT_APP_BACKEND_API_HOST + path  

const Request = async (path, payload = {}, headers = {}, method = "POST") => {

	let options;
	const url = urlOf(path)
	
	headers['Content-Type'] = "application/json"
	
	if (method === "POST") {
		options = {
			method,
			headers,
			body: JSON.stringify(payload)
		}
	}

	const response = await fetch(url, options)
	const JSONresponse = await response.json()

	return { statusCode: response.status, jsonBody: JSONresponse }
}

export default Request;
