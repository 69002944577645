import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Close from '../assets/misc/Close.svg'

function Snackbar(props) {
    const { msg } = props
    const [ display, setDisplay ] = useState("flex")
    const color = msg.error ? "red" : "green"
    const icon = msg.error ? faTimesCircle : faCheckCircle

    useEffect(() => {
        setTimeout(() => setDisplay("none"), 10000)
    }, [])

    return (
        <>
            <div className={ `snackbar-${color} rounded p-3 mt-3 d-${display} justify-content-between align-items-center` }>
                <span className="d-flex align-items-center">
                    <FontAwesomeIcon icon={ icon } className={ `mr-2 text--${color}` } />
                    <span className="px-1">{ Object.values(msg)[0] }</span>
                </span>
                <strong className="cross" onClick={() => setDisplay("none") }>
                    <img src={ Close } alt="close" />
                </strong>
            </div>
        </>
    )
}

export default Snackbar
