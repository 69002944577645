import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Request from '../../services/Request'
import { ReactComponent as KebabIcon } from './assets/KebabIcon.svg'


function MoreActions(props) {
    const { actions, row, setMessageQueue } = props
    const [ showMenu, setShowMenu ] = useState(false)

    const handleClick = async (path) => {
        setMessageQueue([])
        try {
            const { JSONresponse } = await Request(`${path}/${row['_id']}`)
            setMessageQueue([JSONresponse])
        } catch {
            setMessageQueue([{'error': "Server error. Try again after sometime."}])
        }
    }

    return (
        <>
            <span className="kebab-icon" onClick={() => setShowMenu(!showMenu)}>
                <KebabIcon/>
            </span>
            <div className={ showMenu ? "more-actions" : "d-none" } onClick={() => setShowMenu(false)}>
                {
                    actions.map((action, idx) => (
                        !action.noDisplayIf ? (
                            <div className="action">
                                <Link to={ `${action.path}/${row['_id']}` }>
                                            { action.text }
                                </Link>
                            </div>
                        ) : action.noDisplayIf !== !!row[action.dependentOn] && (
                            <div className="action" onClick={() => handleClick(action.path)}>{ action.text }</div>
                        ) 
                    ))
                }
            </div>
        </>
    )
}

export default MoreActions
