import { errorMessages, constraints } from "../constants/messages"

const validateInput = (value, payloadKey) => {

    if (payloadKey === "url") {
        const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        return regex.test(value) ? null : errorMessages({invalid: "URL"})
    } else {
        const constraint = constraints[payloadKey]
        const values = payloadKey === "keywords" ? value.split(",").filter(v => v.length) : value
        const cues = {}

        if (values.length < constraint.min) cues["min"] = constraint.min
        if (values.length > constraint.max) cues["max"] = constraint.max

        if (Object.entries(cues).length) {
            if (payloadKey === "keywords") cues["unit"] = payloadKey
            return errorMessages(cues)
        }

        if (payloadKey === "keywords") {
            return values.every(item => item.length > 2) ? null : errorMessages({invalid: "keywords length. Each keyword must be of at least 2 characters."})
        }
    }
}


export { validateInput }
