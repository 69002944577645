import KE  from '../assets/sidebar/KE.svg';
import KEactive  from '../assets/sidebar/KE active.svg';
import LKC  from '../assets/sidebar/LKC.svg';
import LKCactive  from '../assets/sidebar/LKC active.svg';
import MCE  from '../assets/sidebar/MCE.svg';
import MCEactive  from '../assets/sidebar/MCE active.svg';
import TM  from '../assets/sidebar/TM.svg';
import TMactive  from '../assets/sidebar/TM active.svg';
import RE  from '../assets/sidebar/RE.svg';
import REactive  from '../assets/sidebar/RE active.svg';
import PD  from '../assets/sidebar/PD.svg';
import PDactive  from '../assets/sidebar/PD active.svg';

const sidebarMenu = [
    {
        icon: KE,
        selected: KEactive,
        text: "Keywords extractor",
        path: "/keywords-extractor",
        key: "keywordsExtractor"
    }, {
        icon: LKC,
        selected: LKCactive,
        text: "Long keywords creator",
        path: "/long-keywords-creator",
        key: "longKeywordsCreator",
        subMenu: [
            {
                text: "Text input",
                path: "/text-input",
                key: "TextInput",
            }, {
                text: "Keywords input",
                path: "/keywords-input",
                key: "KeywordsInput"
            }
        ]
    }, {
        icon: MCE,
        selected: MCEactive,
        text: "Main content extractor",
        path: "/main-content-extractor",
        key: "mainContentExtractor"
    }, {
        icon: TM,
        selected: TMactive,
        text: "Taxonomy mapper",
        path: "/taxonomy-mapper",
        key: "taxonomyMapper"
    }, {
        icon: RE,
        selected: REactive,
        text: "Recommendation engine",
        path: "/recommendation-engine",
        key: "recommendationEngine"
    }, {
        icon: PD,
        selected: PDactive,
        text: "Product demo",
        path: "/product-demo",
        key: "productDemo"
    }
]

export default sidebarMenu
