import React, { useState } from 'react'
import PageLength from './PageLength'
import Pagination from './Pagination'
import Table from './Table'
import InfoDisplay from './InfoDisplay'
import ExportButton from './ExportButton'
import './styles.css'
import sortData from './utils/sortData'
import generateExportData from './utils/generateExportData'


function DataTable(props) {
    const { tableData, setMessageQueue, infoHead } = props
    const [ sortProps, setSortProps ] = useState(null)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ pageLength, setPageLength ] = useState(10)

    const tableHead = tableData.tableHead
    const tableRows = tableData.tableBody ? sortData(tableData.tableBody, sortProps) : []
    
    const total = tableRows.length
    const start = total ? pageLength * (currentPage - 1) : 0
    const count = pageLength * currentPage
    const end = !total ? 0 : total < count ? total : count  
    const tableBody = tableRows.slice(start, end)

    const exportData = generateExportData(tableHead, tableBody)
    const paginationProps = { total, pageLength, currentPage, setCurrentPage }
    
    return (
        <>
            <div className={ `bg--light d-${ infoHead ? "flex infoHead" : "none" } justify-content-between align-items-center export-section` } >
                <span className="text-muted mx-3">Showing upto 500 results</span>
                <ExportButton exportData={ exportData } />
            </div>
            <div className={ `card ${ infoHead && "reports" } w-100` }>
                {
                    tableHead && tableBody ? (
                        <Table 
                            className="table table-borderless table-striped"
                            tableData={ { tableHead, tableBody } } 
                            setSortProps={ setSortProps }
                            setMessageQueue={ setMessageQueue }
                        />
                    ) : (
                        <></>
                    )
                }
            </div>
            <div className="d-flex justify-content-between align-items-center my-4">
                <div>
                    <PageLength pageLength={ pageLength } setPageLength={ setPageLength }/>      
                    <InfoDisplay info={ { start, end, total } } />      
                </div>
                <div>
                    <Pagination { ...paginationProps } />
                </div>
            </div>
        </>
    )
}

export default DataTable
